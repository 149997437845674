<template>
  <v-container class="form__container" fluid>
    <v-row no-gutters justify="center">
      <v-col cols="12" md="6">
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h6 text-uppercase" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="text-h5">{{
                $trans("feature_not_available_title")
              }}</span>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <v-btn
                color="blue"
                dark
                depressed
                x-large
                @click="closeDialog() && $root.$emit('openPlanView', false)"
              >
                {{ $trans("change_plan_button_title") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="6" class="d-none d-md-flex align-center justify-center">
        <v-img
          alt="Upgrade"
          contain
          class="ma-2"
          :src="require('@/lib/calendesk-js-library/assets/upgrade.png')"
          max-width="400"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "FeatureNotAvailableDialog",
  mixins: [sharedActions],
  created() {
    pushEvent("featureNotAvailableDialog");
  },
};
</script>

<style lang="scss"></style>
